<template>
  <!--begin::PermissionsList-->
  <div class="row gy-5 g-xl-8">
    <PermissionsList widget-classes="card-xxl-stretch mb-5 mb-xl-8"></PermissionsList>
  </div>
  <!--end::PermissionsList-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PermissionsList from "@/components/settings/PermissionsIndex.vue";

export default defineComponent({
  name: "PermissionsView",
  components: {
    PermissionsList
  },
  mounted() {
    setCurrentPageTitle("Permissions");
  }
});
</script>
