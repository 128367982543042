
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import PermissionsDataTable from "@/components/settings/Tables/PermissionsDataTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import PermissionModal from "@/components/settings/modals/PermissionModal.vue";

export default defineComponent({
  name: "PermissionsOverview",
  data() {
    return {
      pagination: {},
      modalSetup: {
        action: "",
        title: ""
      },
      isThereAModalActive: false,
      currentlySelectedPermission: {
        id: null,
        title: null
      }
    };
  },
  components: {
    LoadingSpinner,
    PermissionsDataTable,
    Pagination,
    DeleteModal,
    PermissionModal
  },
  props: {
    widgetClasses: String
  },
  methods: {
    ...mapActions("PermissionsModule", [
      "fetchData",
      "resetState",
      "setCurrentPage",
      "setSortParams",
      "destroyData",
      "update",
      "createNew"
    ]),
    changePage(pageNumber) {
      this.setCurrentPage(pageNumber);
      this.fetchData();
    },
    updateTableSort(sortParams) {
      this.setSortParams(sortParams);
      this.fetchData();
    },
    openDeleteModal(permission) {
      this.currentlySelectedPermission = permission;
      this.isThereAModalActive = true;
    },
    deleteSelectedPermission() {
      this.destroyData(this.currentlySelectedPermission.id);
      this.isThereAModalActive = false;
      this.currentlySelectedPermission.id = null;
    },
    openEditModal(permission) {
      this.modalSetup.title = "Edit Permission";
      this.modalSetup.action = "edit";
      this.currentlySelectedPermission = permission;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedPermission.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    openAddNewModal() {
      this.resetPermissionData();
      this.modalSetup.title = "";
      this.$nextTick(() => this.modalSetup.title = "Add new Permission");
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    cancelModal() {
      this.modalSetup = {
        action: "",
        title: ""
      };
      this.isThereAModalActive = false;
    },
    resetPermissionData() {
      this.currentlySelectedPermission = {
        id: null,
        title: null
      };
    }
  },
  computed: {
    ...mapGetters("PermissionsModule", [
      "getData",
      "getPaginationData",
      "loading"
    ])
  },
  mounted() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
