
import { defineComponent } from "vue";
import SortingArrow from "@/components/general/sortingArrow.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "PermissionsDataTable",
  components: {
    SortingArrow
  },
  props: {
    tableData: Object
  },
  data() {
    return {
      sortParams: {
        sortedBy: "",
        isOrderAsc: false
      }
    };
  },
  methods: {
    changeSortBy(userSelectedSortValue) {
      if (userSelectedSortValue == this.sortParams.sortedBy) {
        this.sortParams.isOrderAsc = !this.sortParams.isOrderAsc;
      } else {
        this.sortParams.isOrderAsc = false;
      }
      this.sortParams.sortedBy = userSelectedSortValue;
      this.$emit("changeSort", this.sortParams);
    },
    openDeleteModal(permission) {
      this.$emit("deleteModalOpened", permission);
    },
    openEditModal(permission) {
      this.$emit("editModalOpened", permission);
    }
  },
  computed: {
    ...mapGetters("PermissionsModule", ["getSortData"])
  },
  mounted() {
    this.sortParams = this.getSortData;
  }
});
